.background{
    width: 98%;
    height: fit-content;

    margin: 37px 3px 0 3px;
    padding: 0;
    padding-bottom: 40px;

    display: flex;
    flex-direction: column;

    position: absolute;
    top: 0;
    left: 0;

    background: white;
    border-radius: 80px;
    z-index: 2;
}