.title{
    width: fit-content;
    height: fit-content;

    margin: 0 5% 3%;
    padding: 0;

    display: flex;

    text-align: left;

    color: rgb(29, 29, 27);
    font-family: 'Dela Gothic One', Arial, Helvetica, sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0%;
}

.collections{
    width: 100%;
    height: auto;
    max-height: 600px;

    margin: 0 auto;
    padding: 0;
    position: relative;
}

.container{
    width: 100%;
    max-width: 400px;
    height: auto;
    

    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0;
    padding: 0;

    z-index: 0;
}

@media (min-width:1100px){
    .container{
        max-width: 33%;
    }
}

.dress{
    width: 70%;
    max-height: 560px;
    
    display: flex;
    

    margin: 0;
    padding: 0;

    object-fit: contain;
}

.subtitle{
    width: 50%;
    max-height: 100px;

    display: flex;

    margin: 1%;
    padding: 0;
    padding-bottom: 30px;

    color: rgb(29, 29, 27);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0%;
    text-align: center;
}


.arrows{
    width: 90%;
    height: auto;

    position: absolute;
    top: 40%;
    left: 5%;


    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    z-index: 1;
}


.arrow{
    width: 9%;
    max-width: 50px;
    height: auto;

    display: flex;

    background: none;
    border: 0;
}

.prev_arrow{
    background-image: url("../../image/arrow.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.next_arrow{
    background-image: url("../../image/arrow.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    transform: scale(-1, -1);
}