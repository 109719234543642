.welcome{
    width: 100%;
    max-height: 930px;

    aspect-ratio: 1/1.55;

    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;

    background-image: url("../../../image/Sasha_welcom.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    background-color: white;
}

.welcome__title{
    /* виртуальное ателье */
    max-width: 400px;
    height: fit-content;

    margin: 12%;
    padding: 0;

    text-wrap: pretty;

    text-align: left;
}

.welcome__text{
    color: rgb(29, 29, 27);
    font-family: 'Dela Gothic One', Arial, Helvetica, sans-serif;
    font-size: 30px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0%;
}

.welcome__subtitle{
    max-width: 250px;
    height: fit-content;

    margin: 10% 10% 20% auto;
    padding: 0;

    text-wrap: pretty;

    text-align: right;
}

.display-none{
    display: none;
}



@media(70vw > height){
    .welcome{
        background-image: url("../../../image/Sasha_welcom_2.jpg");
    }

    .welcome__title{
        max-width: 600px;
        margin: 20% 10%;
    }

    .welcome__subtitle{
        max-width: 600px;
    }

    .welcome__text{
        font-size: 35px;
    }
}