@import url("./componets/Vanila_js/welcome/welcome.module.css");
@import url("./componets/Vanila_js/ecology/ecology.module.css");
@import url("./componets/Vanila_js/title_subtitle.css");
@import url("./componets/Vanila_js/personalization/personalization.module.css");
@import url("./componets/Vanila_js/footer/footer.css");

.col_col_colections{
  margin: 0;
  padding: 100px 0;
  background: white;
  /* background: linear-gradient(180.00deg, rgb(255, 255, 255),rgba(255, 255, 255, 0) 250%); */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Dela Gothic One";
  src: url(./font/DelaGothicOne-Regular.woff2);
}
