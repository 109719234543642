.container{
    width: 100%;
    height: auto;
    max-height: 150px;

    display: flex;
    position: relative;

    flex-direction: row;
    justify-content:space-between;
    flex-wrap: nowrap;
    
    z-index: 2;
}

.heart{
    width: 0;
    /* width: 15%; */
    height: auto;

    margin: 10%;
    padding: 0;

    object-fit: contain;
    opacity: 0;
}

.exit{
    width: 5%;
    height: auto;

    margin: 5%;
    margin-top: 0;
    padding: 0;

    object-fit: contain;
}


@media (width > 75vh){
    .heart{
        width: auto;
        height: 45%;
        margin: auto 10%;
    }

    .exit{
        width: auto;
        height: 15%;
        margin: 5%;
    }
}