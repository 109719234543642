.modal{
    /* Прямоугольник 1 */
    width: 92%;
    height: auto;

    aspect-ratio: 0.5 / 1;
    background-color: white;
    border-radius: 7vw;
    margin: auto;
    padding: 0;
    
    display: flex;
    position: relative;
    flex-direction: column;

    overflow-y: scroll;
    scrollbar-width: 0;
}

::-webkit-scrollbar {
    width: 0;
}


@media (width > 50vh){
    .modal{
        width: auto;
        height: 92%;
        border-radius: 5vw;
    }
}

@media ((min-width: 500px) and (min-height: 500px)){
    .modal{
        border-radius: 5vw;
    }
}

.editor{
    width: 100%;
    min-height: 100%;
    
    display: flex;
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;
    
    overflow-x: hidden;
}

.info{
    width: 100%;
    height: 500px;
}

.menuSelect{
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;

    z-index: 1;
}

@media (width > 75vh){
    .modal{
        border-radius: 50px;
        aspect-ratio: 0.75 / 1;
    }

    .menuSelect{
        width: 70%;
        max-height: 45vh;
        z-index: 0;
    }
}

@media (width > 100vh){
    .modal{
        border-radius: 50px;
        aspect-ratio: 1 / 1;
    }
}

.hidden{
    overflow: hidden;
}