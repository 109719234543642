.photos{
    width: 100%;
    height: auto;

    margin: 0;
    padding: 0;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;

}

.photo{
    width: 94%;
    height: auto;

    aspect-ratio: 1/1;
    display: flex;

    margin: 3%;
    padding: 0;

    object-fit: contain;

    border-radius: 30px;
}


@media (width > 75vh){
    .photos{
        margin-top: 100px;
    }

    .photo{
        width: 44%;
        margin-bottom: 15%;
    }
}

@media (width > 100vh){
    .photos{
        margin: 0;
    }

    .photo{
        margin: 3%;
    }
}