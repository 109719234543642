.name_description_buy{
    width: 100%;
    height: auto;
    max-height: 65px;

    aspect-ratio: 1 / 0.15;
    margin: 3% 0 0;
    padding: 0;

    display: flex;    
    flex-flow: column wrap;

    z-index: 2;
}

@media (width > 75vh){
    .name_description_buy{
        max-height: 65px;
        margin: 20% 0 0;
    }
}

@media (width > 100vh){
    .name_description_buy{
        margin: 10% 0 0;
    }
}

.title{
    width: 60%;
    height: 60%;

    display: flex;

    margin: 0 0 0 5%;
    padding: 0;

    color: rgb(29, 29, 27);
    font-family: 'Dela Gothic One', Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0%;
    text-align: left;
}

.description{
    width: 60%;
    height: 40%;

    display: flex;

    margin: 0 0 0 5%;
    padding: 0;

    color: rgb(29, 29, 27);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0%;
    text-align: left;
}

.buy{
    width: auto;
    height: 80%;

    display: flex;

    margin: auto 4% auto 1%;
    padding: 0;

    box-sizing: border-box;
    border: 1px solid rgb(29, 29, 27);
    border-radius: 30px;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
    background: rgb(255, 255, 255);

    justify-content: center;
    align-items: center;

    color: rgb(29, 29, 27);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: center;
}

