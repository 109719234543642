.footer{
    margin: 0 auto;

    display: flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: space-between;

    width: 90%;
    border-top: 1px solid #D49BFC;

}

.footer__img{
    max-width: 50px;
    flex-shrink: 0;

    margin: 0 0 10px;
    padding: 0;

    display: flex;

    color: #D49BFC;
    object-fit: contain;
}

.footer__title{
    margin: 4px 0 20px;
    padding: 0;

    display: flex;

    color: #D49BFC;
    font-family: 'Dela Gothic One', Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0%;
    letter-spacing: -0.272px;
}

.footer__for-nalogovaya{
    margin: 5px 5px 17px;
    padding: 0;
}

.footer__subtitle{
    margin: 0;
    padding: 0;

    color: #D49BFC;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 5px;
    font-weight: 700;
    line-height: 9px;
    letter-spacing: 0px;
    text-align: left;
}

.footer__email{
    margin: 3px auto 17px;
    padding: 0;

    color: rgb(212, 157, 252);
    font-family: 'Dela Gothic One', Arial, Helvetica, sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
}

@media (min-width: 500px){ 
    .footer__subtitle{
        font-size: 9px;
    }

    .footer__email{
        font-size: 15px;
    }
}