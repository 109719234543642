.menu{
    width: 89%;
    max-width: 335px;
    max-height: 275px;

    margin: 60px 40px 30px auto;
    padding: 0;

    position: sticky;

    background-image: url("../../image/menu_background.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.text{
    margin: 26px 0 0;
    padding: 0;

    color: rgb(29, 29, 27);
    font-family: 'Dela Gothic One', Arial, Helvetica, sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0%;
    text-align: center;
}

.text:first-child{
    margin: 65px 0 0;
}

@media(min-width:425px){
    .menu{
        margin: 70px 6.5% 30px auto;
    }
}

.hidden{
    overflow: hidden;
}