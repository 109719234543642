.modal{
    /* Прямоугольник 1 */
    width: 300px;
    height: auto;

    aspect-ratio: 1 / 0.75;
    background-color: white;
    border-radius: 50px;
    margin: auto;
    padding: 0;
    
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    overflow-y: scroll;
    scrollbar-width: 0;
}

.text{
    width: 80%;
    height: fit-content;

    margin: 9% 0 0 7%;
    padding: 0;
    padding-bottom: 30px;

    color: rgb(29, 29, 27);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
}