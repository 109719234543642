.assists{
    width: 100%;
    height: auto;
    
    display: flex;
    position: relative;
    flex-direction: column;

    margin: 0;
    padding: 0;

    z-index: 0;
}

.assist{
    width: 100%;
    height: auto;

    aspect-ratio: 1 / 0.175;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: space-between;

    margin: 0;
    padding: 0;  
}

.title{
    width: 100%;
    height: auto;
    margin: 0 5%;
    padding: 0;

    color: rgb(29, 29, 27);
    font-family: "Dela Gothic One", Arial, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0%;
    text-align: left;
}

@media (min-width: 768px){
    .title{
        font-size: 17px;
    }
}

.pointer_left{
    width: 8%;
    height: auto;

    margin: 0 5%;

    display: inline;
    object-fit: contain;
}

.pointer_right{
    width: 8%;
    height: auto;

    margin: 0 5%;

    object-fit: contain;
    display: inline;
}