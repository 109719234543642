.main{
    margin: 0;
    padding: 0;

    background-image: url("../../image/fon.png");
    background-repeat: repeat-y;
    background-size: 175%;
    background-position: calc(50% - 200px);
}

@media (min-width: 700px) {
    .main{
        background-size: 50%;
    }
}

.title{
    width: fit-content;
    height: fit-content;

    margin: 5%;
    margin-top: 100px;
    padding: 0;

    display: flex;

    text-align: left;

    color: rgb(29, 29, 27);
    font-family: 'Dela Gothic One', Arial, Helvetica, sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0%;
    text-align: left;
}

.subtitle{
    width: fit-content;
    height: fit-content;

    display: flex;

    margin: 0 5%;
    padding: 0;
    padding-bottom: 30px;

    color: rgb(29, 29, 27);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 550;
    line-height: 17px;
    letter-spacing: 0%;
    text-align: left;
}