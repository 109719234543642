.ecology{
    width: 100%;
    height: auto;
    max-height: 1800px;

    margin: 0;
    padding: 0;

    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    
}

.eco__blocks{
    margin: 5%;
    padding: 0;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    justify-content: space-around;
}

@media (min-width:900px){
    .eco__blocks{
        flex-direction: row;

        min-height: 450px;
    }

    .ecology__block{
        width: 400px;
    }
}

.ecology__block{
    list-style: none;

    margin: -45px 0;
    padding: 0;

    display: flex;
    flex-direction: row;
    
}

.ecology__title{
    width: fit-content;
    height: fit-content;

    margin: 80px -17px 0;
    padding: 0;

    display: flex;
    flex-direction: column;

    text-align: left;

    color: rgb(29, 29, 27);
    font-family: 'Dela Gothic One', Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0%;
    text-align: left;
}

.ecology__subtitle{
    width: fit-content;
    height: fit-content;

    display: flex;

    margin: 5% 0;
    padding: 0;
    padding-bottom: 30px;

    color: rgb(29, 29, 27);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0%;
    text-align: left;
}

.align_right{
    text-align: right;
}

.ecology__plant{
    width: 60%;
    max-width: 210px;
    max-height: 240px;

    margin: 0;
    padding: 0;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    border: none;
}

.cotton{
    background-image: url("../../../image/cotton.png");
}

.cannabis{
    background-image: url("../../../image/cannabis.png");
}

.flax{
    background-image: url("../../../image/flax.png");
}

.nettles{
    background-image: url("../../../image/nettles.png");
}