.title{
    width: 100%;

    margin: 10%;
    margin-bottom: 5%;
    padding: 0;

    justify-content: end;

    color: rgb(29, 29, 27);
    font-family: 'Dela Gothic One', Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0%;
    text-align: left;
}

.logo{
    width: 5%;
    height: auto;

    margin: 0 0 0 40%;
    padding: 0;

    object-fit: contain;
}

.boxes{
    width: 80%;
    height: fit-content;

    margin: 10px 10% 0 ;
    padding: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
}

.box{
    width: 80px;
    max-height: 80px;

    aspect-ratio: 1 /1;

    margin: 0;
    padding: 0;

    box-sizing: border-box;
    border: 1px solid rgb(29, 29, 27);
    border-radius: 20px;

    background: white;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    text-decoration: none;
}

.box_margin{
    margin-right: 10px;
}

.box_for_contact{
    width: 110px;
    max-height: 80px;

    aspect-ratio: 1 / 0.8;
}

.image{
    max-width: 60px;
    max-height: 40px;

    margin: auto;
    padding: 0;

    object-fit: contain;
}

.subtitile{
    width: fit-content;
    height: fit-content;

    margin: 0;
    margin-bottom: 3px;
    padding: 0;

    color: rgb(29, 29, 27);
    font-family: PT Root UI;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: center;
}

.title_lol{
    width: fit-content;
    height: fit-content;

    margin: 30px 10% 0;
    padding: 0;

    color: rgb(29, 29, 27);
    font-family: 'PT Root UI', Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;
}

.text{
    width: fit-content;
    height: fit-content;

    margin: 0 10%;
    padding: 0;

    display: flex;
    flex-wrap: wrap;

    color: rgb(29, 29, 27);
    font-family: 'PT Root UI', Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;
}