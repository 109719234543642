.modal_overlay{
    width: 100%;
    height: 100%;
    display: flex;
    
    position: fixed;
    top: 0;
    right: 0;

    z-index: 1;
    background-color: rgba(56, 26, 67, 0.6);
}