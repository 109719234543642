.all_title{
    width: 30%;
    height: auto;
    
    display: flex;
    position: relative;
    flex-direction: column;

    margin: 0;
    margin-bottom: 32%;
    padding: 0;

    z-index: 0;
}

.title{
    width: fit-content;
    height: auto;

    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: space-between;

    margin: 40% 15%;
    margin-right: 0;
    padding: 0;

    color: rgb(29, 29, 27);
    font-family: "Dela Gothic One", Arial, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0%;
    text-align: left;

    z-index: unset;
}

@media (min-width: 768px){
    .title{
        font-size: 17px;
        
    }
}

@media (width > 75vh){
    .all_title{
        margin-bottom: 15%;
    }
    .title{
        border-radius: 5vw;
        margin: 25% 37%;
        margin-right: 0;
    }
}

@media (width > 100vh){
    .title{
        border-radius: 5vw;
        margin: 18% 47%;
        margin-right: 0;
    }
}