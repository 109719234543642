.menu{
    /* Rectangle 10 */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    width: 90%;
    height: auto;
    
    /* aspect-ratio: 1 / 0.025; */
    
    margin: 3% 5% 0;
    padding: 0;

    list-style-type: none;

    gap: 4.5%;
}

.menu__main{
    width: 29%;
    height: auto;

    border-radius: 30px;

    margin: 0 2% 0 0;
    padding: 0;
    padding-bottom: 3px;

    border: 1px solid rgb(29, 29, 27);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
    background: rgb(255, 255, 255);

    color: rgb(29, 29, 27);
    font-family: Arial, Helvetica, sans-serif;;
    font-size: 15px;  /*Меняя размер, меняется высота строки*/
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0%;
    text-align: center;
}

@media (min-width: 768px){
    .menu__main{
        font-size: 18px;
    }
    
}

.color{
    width: auto;
    height: 100%;

    aspect-ratio: 1 / 1;

    border: 1px solid rgb(29, 29, 27);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
    background:rgb(29, 29, 27);

    border-radius: 30px;

    margin: 0;
    padding: 0;
}

.text{
    width: 9%;
    height: 85%;

    margin: 0;
    padding: 0;
    

    border: 1px solid rgb(29, 29, 27);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
    background: rgb(255, 255, 255);

    border-radius: 30px;

    color: rgb(29, 29, 27);
    font-family: Arial, Helvetica, sans-serif;;
    font-size: 15px;  /*Меняя размер, меняется высота строки*/
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0%;
    text-align: center;
}

@media (min-width: 768px){
    .text{
        font-size: 18px;
    }
}

.text_material{
    width: 20%;
}


@media (width > 75vh){
    .menu{
        width: 30%;
        height: 5%;

        margin: 23% 5% 0 auto;
        flex-direction: column-reverse;
    }

    .menu__main{
        width: 100%;
    }

    .color{
        width: 60%;
        min-height: 12px;

        margin: 5px auto 5px;
    }

    .text{
        width: 30%;
        margin: 10px auto 0;
    }

    .text:first-of-type{
        margin-bottom: 10px;
    }

    .text_material{
        width: 60%;
    }
}

