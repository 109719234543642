.title{
    width: 100%;

    margin: 10%;
    padding: 0;

    justify-content: end;

    color: rgb(29, 29, 27);
    font-family: 'Dela Gothic One', Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0%;
    text-align: left;
}

.logo{
    width: 5%;
    height: auto;

    margin: 0 0 0 20%;
    padding: 0;

    object-fit: contain;
}

.form{
    width: 80%;
    margin: 0 10% 28px;
    padding: 0;

    position: relative;
}

.input{
    width: 100%;
    height: 27px;

    margin: 0;
    padding: 0;

    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .5);

    color: rgba(0, 0, 0, 1);
    font-family: 'PT Root UI', Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;
};

.input:focus{
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
/*
.input:invalid{
    border-bottom: 1px solid rgba(255, 0, 0, 0.2);
}*/

.input:valid{
    border-bottom: 1px solid rgba(0, 0, 0, .5);
}

.error{
    height: 20px;
    margin-top: 5px;

    padding: 0;
    margin: 0;

    color: #F00;
    font-family: 'PT Root UI', Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;
}

.arrow{
    width: 34px;
    height: 18px;

    position: absolute;
    right: 7px;
    top:5px;

    background: none;
    border: 0;

    background-image: url("../../image/arrow.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    transform: scale(-1, -1);
}

.stage{
    width: 80%;
    height: fit-content;

    padding: 0;
    margin: 0 10%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap
}

.stage:last-of-type > .stick{
    display: none;
}

.circle{
    max-height: 24px;
    max-width: 24px;
    margin: 0;
    padding: 0;

    fill: none;
}

.subtitle{
    width: fit-content;

    margin: 0 3% ;
    padding: 0;

    color: rgb(29, 29, 27);
    font-family: 'PT Root UI', Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: left;
}

.stick{
    max-height: 53px;

    padding: 0;
    margin: 0 12px;
}