.girl{
    width: auto;
    height: 84%;
 
    aspect-ratio: 7 / 20; /*350px / 1000px*/
    background-image: url("../../image/model-0.png");
    background-size: contain;
    background-repeat: no-repeat;

    top: 7%;
    left: 24%;
    display: flex;
    position: absolute;
    flex-direction: column;
    /* overflow-x: inherit; */
    z-index: 1;
}


.girl__part{
    width: 100%;
    height: auto;

    margin: 0 auto;
    padding: 0;
}

.neck{
    width: inherit;
    height: auto;

    object-fit: contain;
}

.handle{
    width: 30%;
    height: auto;

    position: absolute;
    top:35%;
    left: 50%;

    margin: 0;
    padding: 0;
    opacity: 0%;
}

.move_handle_to_right{
    animation-name: handle-right;
    animation-duration: 3.5s;
    /* animation-iteration-count: infinite; */
    animation-timing-function: ease-in-out;
}

.move_handle_to_left{
    animation-name: handle-left;
    animation-duration: 3.5s;
    /* animation-iteration-count: infinite; */
    animation-timing-function: ease-in-out;
}

@keyframes handle-right{
    from{
        transform: translateX(0%) scale(-1, 1);
        left: 30%;
        opacity: 0%;}

    50%{transform: translateX(50%) scale(-1, 1); 
        left: 30%;
        opacity: 100%;}

    to {transform: translateX(0%) scale(-1, 1);
        left: 30%;
        opacity: 0%;}
}

@keyframes handle-left{
    from{transform: translateX(0%); opacity: 0%;}

    50%{ transform: translateX(-50%); opacity: 100%;}

    to { transform: translateX(0%); opacity: 0%;}
}



.move_to_right{
    animation-name: how-to-move-sample-right;
    animation-duration: 3.5s;
    /* animation-iteration-count: infinite; */
    animation-timing-function: ease-in-out;
}

@keyframes how-to-move-sample-right{
    from{transform: translateX(0%); }

    50%{ transform: translateX(15%);}

    to { transform: translateX(0%); }
}



.move_to_left{
    animation-name: how-to-move-sample-left;
    animation-duration: 3.5s;
    /* animation-iteration-count: infinite; */
    animation-timing-function: ease-in-out;
}

@keyframes how-to-move-sample-left{
    from{transform: translateX(0%); }

    50%{ transform: translateX(-15%);}

    to { transform: translateX(0%); }
}

.display_none{
    display: none;
}

.arrows{
    width: 152%;
    height: auto;

    position: absolute;
    top: 50%;
    left: -33%;


    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}


.arrow{
    width: 9%;
    height: auto;

    display: flex;

    background: none;
    border: 0;
}

.prev_arrow{
    background-image: url("../../image/arrow.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.next_arrow{
    background-image: url("../../image/arrow.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    transform: scale(-1, -1);
}