.header {
    width: 100%;
    max-height: 120px;
    
    margin: 0;
    padding: 30px;

    display: flex;
    justify-content: space-between;
    
    
    box-sizing: border-box;
  }

.heart{
    width: 12.5%;
    height: auto;

    margin: 0;
    padding: 0;

    object-fit: contain;
}

.logo{
    width: 64%;
    height: auto;

    margin: 0;
    padding: 0;
    padding-top: 2%;
    box-sizing: content-box;

    object-fit: contain;

}

.menu{
    width: 12.5%;
    height: auto;

    margin: 0;
    padding: 0;

    
    background-image: url("../../image/menu.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;

    border: 0;
    background-color: white;

    border-radius: 12px;
}

@media(min-width:768px){
  .menu{
    background-size: 35px;
  }

  .logo{
    padding-top: 15px;
  }
}