.personalization{
    width: 100%;
    height: auto;
    max-height: 800px;

    margin: 0 0 0;
    padding: 100px 0 0;

    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;

    justify-content: center;

    background: linear-gradient(180.00deg, rgb(255, 255, 255) 0.763%,rgb(238, 175, 253) 70.992%);
}

.personalization__image{
    width: 100%;
    height: 419px;

    
    display: flex;
    
    margin: 0;
    padding: 0;

    background-image: url("../../../image/personalaiz.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
}

@media (width > 75vh){
    .personalization{
        max-height: 1080px;
    }
    .personalization__image{
        width: 100%;
        min-height: 700px;
    }
}